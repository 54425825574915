import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import md5 from 'md5';
import {message as Message} from 'ant-design-vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';

const USER_BASE_URL =  process.env.VUE_APP_USER_BASE_URL
const TOKEN_BASE_URL =  process.env.VUE_APP_TOKEN_BASE_URL
const NEWS_BASE_URL =  process.env.VUE_APP_NEWS_BASE_URL

// 创建实例
let customAxios = axios.create({
    // baseURL: '/hnrb-ics',
    baseURL: process.env.VUE_APP_API_BASE + '/api',
    timeout: 25000,
    headers: { "content-type": "application/json;charset=UTF-8" }
});

// 创建实例
let customAxiosForm = axios.create({
    // baseURL: '/hnrb-ics',
    baseURL: process.env.VUE_APP_API_BASE + '/api',
    timeout: 25000,
    headers:{'Content-Type':'application/x-www-form-urlencoded'}
});

//封装认证信息
function sign(option) {
    if (!option) option = {};
    let data = {
        ...option,
    };
    let timestamp = parseInt(new Date().getTime() / 1000);
    let token = localStorage.getItem(ACCESS_TOKEN) || "";
    let app_secret = process.env.VUE_APP_DINGDUAN_APP_SECRET;
    let sign = [];
    data.timestamp = timestamp;
    data.app_id = process.env.VUE_APP_DINGDUAN_APP_ID;
    token ? data.token = token : "";
    let mdKey = '';
    Object.keys(data).forEach((key) => {
        sign.push(key);
    });
    sign.sort().forEach((item, index) => {
        if (data[item] === undefined || data[item] === null) {
            data[item] = '';
        }
        mdKey += `${item}=${data[item]}`;
    });
    data.sign = md5(`${mdKey}${app_secret}`);
    return data;
}

const err = error => {
    // if (error && error.response) {
    //     const data = error.response.data;
    //     const errorStatus = error.response.status;

    //     switch (errorStatus) {
    //         case 400:
    //             Message.warn((!data.message ? '系统出现异常，请联系系统管理员。' : data.message) + '(400)');
    //             break;
    //         case 401:
    //             // // 刷新token失败只能跳转到登录页重新登录
    //             // store.dispatch('Logout').then(() => {
    //             //     setTimeout(() => {
    //             //         window.location.reload();
    //             //     }, 500);
    //             // });
    //             break;
    //         case 403:
    //             // notification.error({
    //             //     message: '错误码：403',
    //             //     description: '您没有访问权限，请联系管理员！'
    //             // });
    //             //  message.warn('您缺少访问权限，请联系系统管理员。(403)');
    //             break;
    //         case 404:
    //             Message.warn('您访问的资源暂时不见了，');
    //             break;
    //         case 405:
    //             Message.warn('系统出现异常，请稍后。(405)');
    //             break;
    //         case 408:
    //             Message.warn('您的网络可能存在问题，请稍后重试。(408)');
    //             break;
    //         case 500:
    //             Message.warn('数据请求失败。请稍后重试(500)');
    //             break;
    //         case 501:
    //         case 502:
    //         case 503:
    //         case 504:
    //             Message.warn( '连接服务器超时，请稍后重试');
    //             break;
    //         default:
    //             Message.warn( '连接服务器超时，请稍后重试');
    //     }
    // } else {
    //     Message.warn( '连接服务器超时，请稍后重试');
    // }
    return Promise.reject(error);
};

// 请求拦截器，header 中添加 token
customAxios.interceptors.request.use(
    (config) => {
        // 参数签名
        if (config.method == "post" || config.method == "delete" || config.method == "put" ) {
            if(config.url.indexOf(USER_BASE_URL) != -1 || 
               config.url.indexOf(TOKEN_BASE_URL) != -1 ||
               config.url.indexOf(NEWS_BASE_URL) != -1 ){
                config.data = sign(config.data);
            }else{
                config.params = sign(config.params);
                config.data ? "" : config.data = {};
            }
        } else if (config.method == "get") {
            config.params = sign(config.params);
        }
        return config;
    },
    err
);

// 响应拦截器
customAxios.interceptors.response.use(
    (res) => {
        let responseCode = parseInt(res.data.code);
        if (responseCode !== 200) {
            return res.data;
        } else {
            return res.data.data;
        }
    },
    err
);

// 请求拦截器，header 中添加 token
customAxiosForm.interceptors.request.use(
    (config) => {
        // 参数签名
        if (config.method == "post" || config.method == "delete" || config.method == "put" ) {
            if(config.url.indexOf(USER_BASE_URL) != -1 || 
               config.url.indexOf(TOKEN_BASE_URL) != -1 ||
               config.url.indexOf(NEWS_BASE_URL) != -1 ){
                config.data = sign(config.data);
            }else{
                config.params = sign(config.params);
                config.data ? "" : config.data = {};
            }
        } else if (config.method == "get") {
            config.params = sign(config.params);
        }
        return config;
    },
    err
);
// 响应拦截器
customAxiosForm.interceptors.response.use(
    (res) => {
        let responseCode = parseInt(res.data.code);
        if (responseCode !== 200) {
            return res.data;
        } else {
            return res.data.data;
        }
    },
    err
);
let requests = (method, url, data, headers, options) => {
    method = _.lowerCase(method);
    let requestBody = {
        url,
        method,
        headers,
        params: method === 'get' ? data : {},
        data: method !== 'get' ? data : {},
        ...options,
    };
    return customAxios(requestBody);
};

/**
 * 合并url地址
 * @param {String} url
 * @param {Object} params
 */
let mergeUrl = (url, params = {}) => {
    url = _.trim(url, '?&');
    let questionMarkIndex = _.findIndex(url, (o) => {
        return o === '?';
    });
    let connector = questionMarkIndex === -1 ? '?' : '&';
    url = url + connector + qs.stringify(params);

    return _.trim(url, '?&');
};

export { requests as default, customAxios as axios, mergeUrl, customAxiosForm as customAxiosForm };
// export default requests;
