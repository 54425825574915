import store from '@/store';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import md5 from 'md5';
import ObsClient from 'esdk-obs-browserjs/src/obs';
import {transcodings} from '@/api/Dingduan.js';

// 创建ObsClient实例
const obsClient = new ObsClient({
    access_key_id: store.getters.cosConfig.accessKeyId,
    secret_access_key: store.getters.cosConfig.secretAccessKey,
    server: `https://${store.getters.cosConfig.endpoint}`
})

/**
 * 上传文件
 * @param {} text 名称
 * @param {} tageFile 目标文件，必须是数组，且只有一项
 * @returns 上传成功后的文件地址
 */
export function doUploader(text, tageFile, config) {
    if (tageFile.length <= 0) return false;

    let file = tageFile[0];
    let time = moment(new Date()).format('YYYYMMDD');
    let path = '';
    let suffix = file.name.split('.')[file.name.split('.').length - 1];
    let key = md5(uuidv4()); // uuid(35);
    let name = `${key}.${suffix}`;

    if (file.type.includes('image')) path = 'image/';
    else if (file.type.includes('video')) path = 'video/';

    path = `${path}${time}`;

    this.$message.loading(`${text}上传中`);
    return new Promise((resolve, reject) => {

        obsClient.putObject({
            Bucket: store.getters.cosConfig.bucketName, // 桶名
            Key: `${path}/${key}.${suffix}`, // 路径 + 文件名 ${path}/
            SourceFile: file,
            ProgressCallback: config && config.ProgressCallback ? config.ProgressCallback : callback
        }, (err, data) => {
            // 上传成功
            if (data) {
                if (file.type.includes('video')){
                    // this.$message.loading(`${text}开始转码`);
                    let successInfo = getFileSuccess(file, path, key, name);
                    transcodings({"url":successInfo.url}).then(res => {
                        if(res.code === 0){
                           
                            // name = `${key}_1440.${suffix.toLowerCase()}`;
                            name = `${key}_1440.mp4`;
                            successInfo = getFileSuccess(file, path, key, name);
                            // this.$message.success(`${text}转码成功`);
                            this.$message.success(`${text}上传成功`);
                            console.log(successInfo);
                            resolve(successInfo);
                        }else{
                            console.log(successInfo);
                            this.$message.warn(`${text}转码失败`);
                            // this.$message.success(`${text}上传成功`);
                            resolve(successInfo);
                        }
                    }).catch(err => {
                        console.log(successInfo);
                        this.$message.warn(`${text}转码失败`);
                        // this.$message.success(`${text}上传成功`);
                        resolve(successInfo);
                    })
                }else{
                    let successInfo = getFileSuccess(file, path, key, name);
                    console.log(successInfo);
                    this.$message.success(`${text}上传成功`);
                    resolve(successInfo);
                }
            }
            if (err) {
                this.$message.error('上传文件失败，请重试');
                reject(err);
            }
        });

    });
};

export function getFileSuccess(file, path, key, name) {
    return {
        'fileName': name,
        'size': file.size,
        // 'url': `https://${store.getters.cosConfig.bucketName}.${store.getters.cosConfig.endpoint}/${path}/${name}`, // https: ${location.protocol}
        'url': `${store.getters.cosConfig.cosHost}/${path}/${name}`, // https: ${location.protocol}
    };
}

export function callback(transferredAmount, totalAmount, totalSeconds) {
    // 获取上传平均速率（KB/S）
    console.log(transferredAmount * 1.0 / totalSeconds / 1024);
    // 获取上传进度百分比
    console.log(transferredAmount * 100.0 / totalAmount);
    // 百分比取整数
    console.log(Math.floor(transferredAmount * 100.0 / totalAmount));
};
