import {getConfigurationParams } from '@/api/Config';
import Vue from 'vue';
import { CODELISTS, CONFIGLIST } from '@/store/mutation-types';

const code = {
    state: {
        configList: [],
    },
    getters: {
        getConfigList(state) {
            return state.configList;
        }
    },
    mutations: {
        SET_CONFIG: (state, data) => {
            state.configList = data;
        }
    },
    actions: {
        getConfigurationList({ commit }) {
            return new Promise((resolve, reject) => {
                getConfigurationParams({ page: 0, size: 999 }).then(res => {
                    commit('SET_CONFIG', res.data);
                });
            });
        }
    }
};

export default code;
