<!--
 * @Descripttion: 
 * @Author: intic
 * @Date: 2024-07-29 17:03:18
 * @LastEditors: intic
 * @LastEditTime: 2024-07-30 14:28:02
-->
<template>
    <a-modal
        :visible="visible"
        title="账号异常"
        :centered="true"
        :maskClosable="false"
        :keyboard="false"
        :destroyOnClose="true"
        :footer="false"
        @cancel="closeModal"
        class="BanContainer"
    >
        <iframe :src="appealUrl" style="width: 100%; height: 680px;"></iframe>
    </a-modal>
</template>

<script>
import { ACCESS_TOKEN } from '@/store/mutation-types';
export default {
    name: 'BanModal',
    props: {
        type: {
            type: String,
            default: 'post', // post 帖子   comment 评论
        }
    },
    data () {
        return {
            visible: false,
            appealUrl: '',
        }
    },
    methods: {
        getAppealUrl (token = null) {
            const accessToken = localStorage.getItem(ACCESS_TOKEN);
            this.appealUrl = `https://static.${process.env.VUE_APP_PATH_NAME}/activity-sharepage/index.html#/pages/abutment/abnormal?token=${token ? token : accessToken}&isPc=1`;
        },
        checkBanFn () {
            return new Promise(resolve => {
                let banData = localStorage.getItem("banData");
                if (banData) {
                    banData = JSON.parse(banData);
                }
                if (this.type == 'post' && banData.ban_type == 1 && banData.ban_range_type.indexOf("3") > -1) {
                    this.getAppealUrl();
                    this.visible = true;
                    resolve(false)
                } else if (this.type == 'comment' && banData.ban_type == 1 && banData.ban_range_type.indexOf("4") > -1) {
                    this.getAppealUrl();
                    this.visible = true;
                    resolve(false)
                } else {
                    resolve(true)
                }
            })
        },
        openModal (token) {
            this.getAppealUrl(token);
            this.visible = true;
        },
        closeModal () {
            this.visible = false;
        }
    }
}
</script>

<style lang="less" scoped>

</style>