//顶端接口api
import {axios}  from '@/utils/request';

const NEWS_BASE_URL =  process.env.VUE_APP_NEWS_BASE_URL
const APP_TRAN_URL =  process.env.VUE_APP_TRAN_URL


/**
 * 视频转码
 */
export function transcodings(data) {
    return axios({
        url: `${APP_TRAN_URL}/api/hw/transcodings`,
        method: 'post',
        data,
    });
}
/**
 * 关注接口
 * @param {int} is_change 0
 * @param {String} target_id 目标id
 * @param {int} type 未关注->关注：type=1 关注=>未关注：type=2
 */
export function followedUser(data) {
    return axios({
        url: `${NEWS_BASE_URL}/api/attention/operate`,
        method: 'post',
        data: data
    });
}

/**
 * 关注D人
 */
export function getFollowedUsers(param) {
    return axios({
        url: `${NEWS_BASE_URL}/api/attention/list`,
        method: 'get',
        params: param
    });
}

/**
 * 用户组接口
 * @param {String} u_ids 用户ID组 用逗号分隔拼接
 */
export function getUserinfobyids(param) {
    return axios({
        url: `${NEWS_BASE_URL}/api/attention/userinfobyids`,
        method: 'get',
        params: param
    });
}

/**
 * 用户页接口
 * @param {String} u_ids 用户ID组 用逗号分隔拼接
 */
export function getUserHomepage(param) {
    return axios({
        url: `${NEWS_BASE_URL}/api/user/homepage`,
        method: 'get',
        params: param
    });
}
