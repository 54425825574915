const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    nickname: state => state.user.name,
    isLogin: state => state.user.isLogin,
    userInfo: state => state.user.info,
    configList: state => state.code.configList,
    cosConfig: state => state.app.cosConfig,
    mapsConfig: state => state.app.mapsConfig,
    
};

export default getters;
