import moment from 'moment';
import {ACCESS_TOKEN } from '@/store/mutation-types';


export function timeFix() {
    const time = new Date();
    const hour = time.getHours();
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好';
}

/**
 * 获取页面链接中的参数
 * @param {*} url 链接
 * @returns 参数对象
 */
export function getRequestParams(url) {
    var theRequest = new Object();
    if (url.indexOf('?') != -1) {
        var str = url.split('?')[1];
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest;
}

/**
 * 获取多少时间是多少时间前的时间
 */
export function formaterTime(value) {
    //获取要过滤的时间与当前时间的时间差。（单位秒）
    const d = Math.floor((Date.now() - value.getTime()) / 1000);
    const format = 'YYYY-MM-DD';
    const arr = [0, 60, 60 * 60];
    // const arr = [0, 60, 60 * 60, 60 * 60 * 24, 60 * 60 * 24 * 30, 60 * 60 * 24 * 30 * 12];
    const _arr = ['刚刚', '分钟前', '小时前', '天前', '月前', '年前'];
    /* 循环判断 */
    for (var i = arr.length - 1; i >= 0; i--) {
        if (d > arr[i]) {
            if (i == 0) {
                //刚刚
                return _arr[i];
            } else if (i == 1) {
                //分钟前
                return Math.floor(d / arr[i]) + _arr[i];
            } else if (i == 2) {
                
                if (Math.floor(d / arr[i]) > 71) {
                    //大于72小时：2022-07-04
                    return moment(value).format(format);
                } else if(Math.floor(d / arr[i]) > 47) {
                    //大于48小时小于72小时-2天前
                    return '2天前';
                }if(Math.floor(d / arr[i]) > 23) {
                    //大于24小时且小于48小时-1天前
                    return '1天前';
                }else{
                    //大于60分钟且小于24小时-XX小时前，
                    return Math.floor(d / arr[i]) + _arr[i];
                }
            }
        }
    }
    return moment(value).format(format);
}

/**
 * 没有登录的弹窗
 * @param {*} url 链接
 * @returns 参数对象
 */
export function  isNotLoginConfirm(){
    this.$confirm({
        title: '请登录',
        content:"您还没有登录是否前往登录页面？",
        okText: '登录',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
            let routeData = this.$router.resolve({ path:'/login'});
            window.open(routeData.href, '_blank'); //打开新标签
        },
        onCancel: () => {
        }
    });
}

/**
 * 获取计算关注和帖子数量缩写为k
 * @returns 参数对象
 */
export function numberFormat(number){
    if(number && number >= 10000){
        const result = number/10000;
        const realVal = parseFloat(result).toFixed(1);
        return realVal+"W";
    }else if(number && number >= 1000){
        const result = number/1000;
        const realVal = parseFloat(result).toFixed(1);
        return realVal+"K";
    }else{
        return number;
    }
}

/**
 * 获取计算关注和帖子数量缩写为w
 * @returns 参数对象
 */
export function numberFormatMess(number){
    if(number && number >= 1000){
        const result = number/1000;
        const realVal = parseFloat(result).toFixed(1);
        return realVal+"k";
    }else{
        return number;
    }
}

export function getEquipment() {
    let platform = get_os().split("/")[0];
    if (platform == "Win" || platform == "Mac") {
        return true; // pc
    } else {
        return false;  // 移动端
    }
}


export function get_os() { 
    var os = navigator.platform; 
    var userAgent = navigator.userAgent; 
    var info = ""; 
    var tempArray = ""; 
    if (os.indexOf("Win") > -1) { 
        info += "Win"; 
    } else if (os.indexOf("Mac") > -1) { 
        info += "Mac"; 
    } else if (os.indexOf("X11") > -1) { 
        info += "Unix"; 
    } else if (os.indexOf("Linux") > -1) { 
        info += "Linux"; 
    } else { 
        var isAndroid = userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端 
        var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
        if (isAndroid) { 
            info += "Android"; 
        } else if (isiOS) { 
            info += "iOS"; 
        } else { 
            info += "Other"; 
        } 
    } //以下为浏览器信息 
    info += "/"; 
    if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) { 
        tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent); 
        info += tempArray[1] + tempArray[2]; 
    } else if (/MSIE \d+\.\d+/.test(userAgent)) { 
        tempArray = /MS(IE) (\d+\.\d+)/.exec(userAgent); 
        info += tempArray[1] + tempArray[2]; 
    } else if (/[Cc]hrome\/\d+/.test(userAgent)) { 
        tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent); 
        info += tempArray[1] + tempArray[2]; 
    } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) { 
        tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent); 
        info += tempArray[3] + tempArray[1]; 
    } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) { 
        tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent); 
        info += tempArray[1] + tempArray[2]; 
    } else { 
        info += "unknown"; 
    } 
    return info; 
} 

// 跳转创作中台发帖
export function routerCreator () {
    let token = localStorage.getItem(ACCESS_TOKEN);
    let url = `https://mp.${process.env.VUE_APP_PATH_NAME}/#/post?postJump=1&token=${token}`
    window.open(url)
}