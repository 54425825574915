<template>
    <a-modal
        :visible="visible"
        :centered="true"
        :maskClosable="false"
        :keyboard="false"
        :destroyOnClose="true"
        :footer="false"
        @cancel="closeModal"
        class="loginContainer"
    >
        <div class="loginBox">
            <a-form
                id="formLogin"
                class="user-layout-login"
                ref="formLogin"
                :form="form"
                @submit="handleSubmit">
                <a-tabs
                    :activeKey="customActiveKey"
                    :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
                    @change="handleTabClick"
                >
                    <a-tab-pane key="tab1" tab="验证码登录">
                        <a-form-item>
                            <a-input size="large" type="text" placeholder="请输入手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[345789]\d{9}$/, message: '请输入正确格式的手机号' }], validateTrigger: 'change'}]">
                            <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                            </a-input>
                        </a-form-item>
                        <a-row :gutter="16">
                            <a-col class="gutter-row" :span="13">
                            <a-form-item>
                                <a-input size="large" type="text" placeholder="请输入验证码" :max-length="6" v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]">
                                <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                                </a-input>
                            </a-form-item>
                            </a-col>
                            <a-col class="gutter-row" :span="11">
                            <a-button
                                type="primary"
                                class="getRegCodeBtn"
                                tabindex="-1"
                                :disabled="state.smsSendBtn"
                                @click.stop.prevent="getCaptcha"
                                v-text="!state.smsSendBtn && '获取验证码'|| '重新获取'+(state.time+'s')"
                            ></a-button>
                            </a-col>
                        </a-row>
                        <a-row class="userAgreement-box">
                            未注册手机验证后自动登录，注册即代表同意《
                            <a href="https://ddactivity.dingxinwen.cn/creator/static/html/userAgreement.html" title="顶端新闻协议" target="_blank">顶端新闻协议</a>
                            》·《
                            <a href="https://ddactivity.dingxinwen.cn/creator/static/html/ddGuide.html" title="隐私政策" target="_blank">隐私政策</a>
                            》
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="tab2" tab="密码登录">
                        <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" message="登录失败" />
                        <a-form-item>
                            <a-input
                            size="large"
                            type="text"
                            placeholder="请输入手机号"
                            v-decorator="[
                                'username',
                                { rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确格式的手机号' }], validateTrigger: 'change'}
                            ]"
                            >
                            <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                            </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-input-password
                            size="large"
                            placeholder="请输入密码"
                            v-decorator="[
                                'password',
                                {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
                            ]"
                            >
                            <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                            </a-input-password>
                        </a-form-item>
                    </a-tab-pane>
                </a-tabs>
                <a-button
                    size="large"
                    type="danger"
                    htmlType="submit"
                    class="loginBtn "
                    :loading="state.loginBtn"
                    :disabled="state.loginBtn"
                >登录</a-button>
            </a-form>
            <a-divider style="margin: 10px 0px" />
            <div class="login-footer" v-if="!isWx" >
                <span class="web-icon" />
                <a title="下载顶端APP" :href="downloadUrl" target="_blank">下载顶端APP</a>
            </div>
            <div class="login-footer" v-if="isWx" >
                <div class="edit-vx">
                    <img class="edit-vx-img"  @click="wxLogin" :src="require('@/assets/images/icon/weixin.png')" title="分享到QQ"/>
                </div> 
            </div>
        </div>
        <BanModal ref="banModalRef" />
    </a-modal>
</template>

<script>
import md5 from 'md5'
import wx from 'weixin-js-sdk';
import { timeFix } from '@/utils/utils'
import store from '@/store';
import { Message } from 'ant-design-vue';
import BanModal from '@/views/website/components/BanModal/index.vue';
import { getSmsCaptcha,smsLogin,login,logout,saveUserInfo,getAdminUserInfo,getUserInfo, getUserBanInfo } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types';

export default {
    model: {
            // prop: 'visible',
            event: 'close'
    },
    components: { BanModal },
    // props: {
    //     visible: { // 控制窗口是否显示，必须项
    //         required: false,
    //         type: Boolean,
    //         default: true
    //     },
    // },
    data () {
        return {
            visible:true,
            customActiveKey: 'tab1',
            loginBtn: false,
            // login type: 0 email, 1 username, 2 telephone
            loginType: 0,
            isLoginError: false,
            requiredTwoStepCaptcha: false,
            stepCaptchaVisible: false,
            form: this.$form.createForm(this),
            state: {
                time: 30,
                loginBtn: false,
                // login type: 0 email, 1 username, 2 telephone
                loginType: 0,
                smsSendBtn: false
            },
            downloadUrl:"https://a.app.qq.com/o/simple.jsp?pkgname=cn.dahebao",

            isWx:false,
        }
    },
    created () {
    },    
    mounted() {
        let self = this;
        //是否小程序
        var ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) === 'micromessenger' || ua.indexOf('micromessenger') !== -1) {
                //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                wx.miniProgram.getEnv(function(res) {
                    if (res.miniprogram) {
                        self.isWx = true;
                        console.log('在小程序')
                    } else {
                        console.log('不在小程序')
                        self.isWx = false;
                    }
                });
        } else {
            console.log('在浏览器')
            self.isWx = false;
        }
    },
    methods: {
        // ...mapActions(['Login', 'Logout','GetInfo']),
        // 输入账号或者邮箱 通过正则判断当前使用的是什么 暂时只有账号
        handleUsernameOrEmail (rule, value, callback) {
            const { state } = this
            const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
            //   if (regex.test(value)) {
            //     state.loginType = 0
            //   } else {
                state.loginType = 1
            //   }
            callback()
        },
        //点击tab 切换登录方式
        handleTabClick (key) {
            this.customActiveKey = key
        },
        //提交表单
        handleSubmit (e) {
            e.preventDefault()
            const {
                form: { validateFields },
                state,
                customActiveKey,
                Login
            } = this

            state.loginBtn = true

            const validateFieldsKey = customActiveKey === 'tab2' ? ['username', 'password'] : ['mobile', 'captcha']

            validateFields(validateFieldsKey, { force: true }, (err, values) => {
                if (!err) {
                console.log('login form', values)
                const loginParams = { ...values }
                delete loginParams.username
                if(customActiveKey === 'tab2'){
                    //账号密码登录
                    let params = {
                        u_cellphone:values.username,
                        password:values.password
                    }
                    login(params)
                        .then((res) => this.loginSuccess(res))
                        // .catch(err => this.requestFailed(err))
                        .finally(() => {
                            state.loginBtn = false
                        });

                }else{
                    //短信登录
                    let params = {
                        u_cellphone:values.mobile,
                        captcha:values.captcha
                    }
                    smsLogin(params)
                        .then((res) => this.loginSuccess(res))
                        // .catch(err => this.requestFailed(err))
                        .finally(() => {
                            state.loginBtn = false
                        });
                    
                }

                }
                setTimeout(() => {
                    state.loginBtn = false
                }, 600)
            })
        },
        //获取验证码
        getCaptcha (e) {
            e.preventDefault()
            const { form: { validateFields }, state } = this

            validateFields(['mobile'], { force: true }, (err, values) => {
                if (!err) {
                state.smsSendBtn = true

                const interval = window.setInterval(() => {
                    if (state.time-- <= 0) {
                    state.time = 60
                    state.smsSendBtn = false
                    window.clearInterval(interval)
                    }
                }, 1000)

                const hide = this.$message.loading('验证码发送中..', 0)
                getSmsCaptcha(values.mobile).then(res => {
                    setTimeout(hide, 2500)
                    this.$notification['success']({
                    message: '提示',
                    description: '验证码发送成功',
                    duration: 8
                    })
                }).catch(err => {
                    setTimeout(hide, 1)
                    clearInterval(interval)
                    state.time = 60
                    state.smsSendBtn = false
                    this.requestFailed(err)
                })
                }
            })
        },
        //获取登录用户有权限的页面
        setMenu() {
            // 延迟 1 秒显示欢迎信息
            setTimeout(() => {
                this.$notification.success({
                message: '欢迎',
                description: `${timeFix()}，欢迎回来`
                })
            }, 1000)
            // this.$router.push({
            //     path: "/",
            // });
            this.closeModal();
        },
        //保存登录用户的信息
        async saveUserInfo(){
            const userResult =  await getUserInfo();
            if(!userResult || userResult.code != 0){
                return;
            }
            const userData = userResult.data;
            if(userData){
                //用户认证信息列表（merchant 商户认证 identity_verification 实名认证 reporter记者认证 copyreader编辑认证 ）
                let isCertification = "0";
                let certificationType = "0";
                userData.identities_info &&  userData.identities_info.forEach((item, index) => {
                    if(item === "identity_verification"){
                        isCertification = "1";
                    }else if(item === "merchant"){
                        certificationType = "2";
                    }else if(item === "copyreader" || item === "reporter"){
                        certificationType = "3";
                    }
                });
                if(certificationType == '0' && isCertification=="1"){
                    certificationType = '1'
                }
                let userInfo = {
                    "nickname": userData.u_nickname ?  userData.u_nickname : userData.u_nickname , //昵称 
                    "certificationType": certificationType,//认证类型0：未认证 1：个人认证 2：企业认证 3：机构认证
                    "isCertification": isCertification,//是否实名认证 0：未认证 1：已认证
                    "profession": "",//职业
                    "signature": "",//签名
                    "registered": new Date().getTime(), //注册时间 13位时间戳
                    "mobile": userData.u_cellphone,
                    "email": userData.u_email,
                    "introduction": userData.description,//简介
                    "isMgr": "1",//是否管理端用户
                    "designation": "" //称号
                }
                let userAvatar = {
                    url : userData.u_avatar,
                }
                // let userAccount{
                //     devicecode : "",//极光推送用，唯一设备码极光推送用，唯一设备码
                // }
                let data = {
                    userInfo:userInfo,
                    userAvatar:userAvatar,
                    uuid:userData.u_id,
                }
                saveUserInfo(data).then(res => {

                });
            }

        },
        //请求成功的回调
        loginSuccess (res) {
            console.log(res)
            if (res.code == 0) {
                localStorage.setItem(ACCESS_TOKEN,res.data.token);
                store.dispatch('Login');
                store.dispatch('GetInfo');
                this.isLoginError = false
                this.setMenu();
                this.saveUserInfo();
                this.getUserBanInfo();
            } else if (res.code == 40002) {
                this.$refs.banModalRef.openModal(res.data.token)
            } else {
                this.$notification.error({
                    message: '提示',
                    description: res.msg,
                })
            }
        },
        // 获取用户封禁信息
        getUserBanInfo () {
            getUserBanInfo().then(res => {
                if (res.code == 0) {
                    if (res.data.ban_type == 2) {
                        Message.warn(res.data.ban_reason);
                        localStorage.removeItem(ACCESS_TOKEN);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    } else {
                        localStorage.setItem("banData", JSON.stringify(res.data));
                    }
                } else {
                    console.log(res.msg);
                }
            });
        },
        //登陆失败回调
        requestFailed (err) {
            this.isLoginError = true
            this.$notification['error']({
                message: '错误',
                description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
                duration: 4
            })
        },
        /**
         * 关闭窗口
         */
        closeModal() {
            this.$forceUpdate();
            this.visible = false;
            // this.$emit('close', true);
        },
        wxLogin(){
            let self = this;
            // 小程序跳转方法
            wx.miniProgram.navigateTo({
                url:'/pages/login/index',        // 指定跳转至小程序页面的路径
                success: (res) => {
                    console.log(res);   // 页面跳转失败的回调函数
                    self.visible = false;
                },
                fail: (err) => {
                    console.log(err);   // 页面跳转失败的回调函数
                    self.$message.error('微信登录调用失败')
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.loginContainer {
  width: 400px;
  height: 100%;
//   background: url(~@/assets/images/login/bg.jpg) no-repeat;
//   background-size: cover;
//   position: relative;
  /deep/.ant-modal{
      width: 410px !important;
  }
  .loginBox {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    // position: absolute;
    // top: 50%;
    // right: 16vw;
    // margin-top: -240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top:30px;

    /deep/.ant-tabs-ink-bar {
      height: 5px;
      margin-left:5px;
      background-color: #d80c18;
      width: 80px !important;
    }

    /deep/.ant-input:hover{
        border-color: #f5222d;
    }
    /deep/.ant-input:focus{
        border-color:#f5222d;
        box-shadow: none;
    }

     /deep/.ant-tabs-tab{
         font-size:18px;
         padding:10px 0px;
         margin:0 15px 0 0;
         color: black !important;
     }
    /deep/.ant-tabs-tab-active {
        font-weight: bold;
        color: black !important;
        font-weight: 500;
    }

    /deep/.ant-tabs-bar {
      margin-bottom: 20px;
      text-align: start !important;
    }

    /deep/.ant-tabs-content {
      width: 100%;
    }
    .getRegCodeBtn {
        height: 40px;
        background-color: #ffffff;
        border-color: #ffffff;
        color: #d80c18;
        border-radius: 0;
        border: 0 solid transparent;
        box-shadow: 0 0 0 rgb(0 0 0 / 5%);
    }

    .loginBtn {
      width: 100%;
     height: 45px;
      margin-top: 0px;
    }
    .login-footer{
        margin:auto 0;
        a{
            margin-left: 5px ;
            color:#d80c18;
        }
    }
    .userAgreement-box{
        font-size: 13px;
        color: grey;
        margin-bottom: 10px;
        a{
        }
    }
  }
  .edit-vx-img{
      width:40px;
      height:40px;
  }
}
</style>
