import {axios}  from '@/utils/request';
/**
 * 获取配置参数
 */
export function getConfigurationParams(params) {
    return axios({
        url: `/oapi/common-param/all.json`,
        method: 'get',
        params: params
    });
}

/**
 * 获取首页参数
 */
export function getHomeIndex(data) {
    let page = data.page ? data.page : 0;
    let size = data.size ? data.size : 10;
    let loginUserId = data.loginUserId ? data.loginUserId : undefined;
    return axios({
        url: `/oapi/home/index/${loginUserId}/${page}/${size}.json`,
        method: 'get',
    });
}
