import Vue from 'vue';

const VUE_APP_QQ_MAPS_KEY =  process.env.VUE_APP_QQ_MAPS_KEY;
const VUE_APP_QQ_MAPS_NAME =  process.env.VUE_APP_QQ_MAPS_NAME;

const app = {
    state: {
        // 集成 COS SDK 配置
        cosConfig: {
            // id: 'AKIDCDyTjkVQ5UcS2MPBgurXv2tSkmZ5JGwL',
            // secret: 'B5j7E4cuud6HDsGMDn56qJUQ5Ta2EAoB',
            // access_web: process.env.VUE_APP_COS_WEB,
            // bucket_name: process.env.VUE_APP_COS_NAME,
            // region: 'ap-beijing'
            userName: 'comobs',
            accessKeyId: 'DC3RAODJOYKQGLOTKAW3',
            secretAccessKey: 'EAbVaWcxwNDNp5weGW9VPlvsWWiYkv6ojfNvb2lC',
            endpoint: process.env.VUE_APP_OBS_ENDPOINT,
            bucketName: process.env.VUE_APP_OBS_BUCKETNAME,
            cosHost: process.env.VUE_APP_OBS_COSHOST
        },
        // 集成 腾讯地图配置
        mapsConfig: {
            key:VUE_APP_QQ_MAPS_KEY,
            name:VUE_APP_QQ_MAPS_NAME
        }
    },
    mutations: {
    },
    actions: {
    }
};

export default app;
