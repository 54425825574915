import LoginModal from '@/views/website/user/LoginModal';
import Vue from 'vue'

const showDrawer = (options = {}) => {
  const DrawerConstructor = Vue.extend(LoginModal);
  let data = {}, methods = {};
  Object.keys(options).forEach(e => {
      if (typeof options[e] === 'function') {
          methods[e] = options[e];
      } else {
          data[e] = options[e];
      }
  })
  let instance = new DrawerConstructor({
      data: data,
      methods: methods
  });
  document.body.appendChild(instance.$mount().$el);
}
export default showDrawer;
