import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/website/HomeView.vue'),
        meta: {
            title: '汤圆社区'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/website/user/Login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/topics',
        name: 'topic',
        component: () => import('../views/website/topic/index.vue'),
        meta: {
            title: '帖子广场'
        }
    },
    {
        path: '/topics/detail/:id',
        name: 'topic-detail',
        component: () => import('../views/website/topic/detail.vue'),
        meta: {
            title: '帖子详情'
        }
    },
    {
        path: '/circles',
        name: 'circles',
        component: () => import('../views/website/circle/index.vue'),
        meta: {
            title: '特色圈子'
        }
    },
    {
        path: '/circles/:id',
        name: 'circles-detail',
        component: () => import('../views/website/circle/detail.vue'),
        meta: {
            title: '圈子详情'
        }
    },{
        path: '/circles/:id/followers',
        name: 'circles-followers',
        component: () => import('../views/website/circle/followers.vue'),
        meta: {
            title: '成员列表'
        }
    },{
        path: '/subjects',
        name: 'subjects',
        component: () => import('../views/website/subjects/index.vue'),
        meta: {
            title: '话题广场'
        }
    },
    {
        path: '/subjects/:id',
        name: 'subjects-detail',
        component: () => import('../views/website/subjects/detail.vue'),
        meta: {
            title: '话题详情'
        }
    },{
        path: '/mine',
        name: 'mine',
        component: () => import('../views/website/mine/index.vue'),
        meta: {
            title: '我的空间'
        }
    },{
        path: '/mine/message',
        name: 'mine-message',
        component: () => import('../views/website/mine/message.vue'),
        meta: {
            title: '我的消息'
        }
    },{
        path: '/search',
        name: 'search',
        component: () => import('../views/website/search/index.vue'),
        meta: {
            title: '搜索'
        }
    },{ //往下是小程序的
        path: '/applet',
        name: 'applet-home',
        component: () => import('../views/applet/home.vue'),
        meta: {
            title: '汤圆社区',
            keepAlive:true
        }
    },{ 
        path: '/applet/search',
        name: 'applet-search',
        component: () => import('../views/applet/search/index.vue'),
        meta: {
            title: '搜索',
            
        }
    },{ 
        path: '/applet/activity/hot',
        name: 'applet-activity-hot',
        component: () => import('../views/applet/hot/hotActivity.vue'),
        meta: {
            title: '热门活动'
        }
    },{ 
        path: '/applet/subject/hot',
        name: 'applet-subject-hot',
        component: () => import('../views/applet/hot/hotSubject.vue'),
        meta: {
            title: '热门话题',
            
        }
    },{ 
        path: '/applet/subject/detail/:id',
        name: 'applet-detail-detail',
        component: () => import('../views/applet/subject/detail.vue'),
        meta: {
            title: '话题详情',
            
        }
    },{ 
        path: '/applet/topic/detail/:id',
        name: 'applet-topic-detail',
        component: () => import('../views/applet/topic/detail.vue'),
        meta: {
            title: '帖子详情'
        }
    },{ 
        path: '/applet/user/detail/:id',
        name: 'applet-user-detail',
        component: () => import('../views/applet/user/detail.vue'),
        meta: {
            title: '用户详情',
            
        }
    },{ 
        path: '/applet/topic/add',
        name: 'applet-topic-add',
        component: () => import('../views/applet/topic/topicAdd.vue'),
        meta: {
            title: '发布帖子',
            
        }
    }, { 
        path: '/applet/circles/detail/:id',
        name: 'applet-circles-detail',
        component: () => import('../views/applet/circles/detail.vue'),
        meta: {
            title: '圈子详情',
            
        }
    }, {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue')
    }
];

const router = new VueRouter({
    base: process.env.VUE_APP_DINGDUAN_BASE_URL,
    // exact: true,
    routes,
});

export default router;
