import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {beforeEach} from '@/utils/routerUtils';
import store from './store';
// import Vconsole from 'vconsole';
import {ACCESS_TOKEN } from '@/store/mutation-types'; // DEFAULT_LAYOUT_MODE
import VueRouter from 'vue-router';
import 'ant-design-vue/dist/antd.css';
import '@/assets/styles/main.less';
import '@/assets/styles/font-size.less';
import '@/utils/rem';
import { Button } from 'ant-design-vue';
import { doUploader } from '@/utils/upload.js';
import _extends from 'babel-runtime/helpers/extends';
import modalConfirm from 'ant-design-vue/es/modal/confirm';
import showDrawer from '@/views/website/components/tools/LoginModal';
import { Row, Col, Layout, Menu, Breadcrumb, message, notification, Spin, Icon, Form, Table, Input } from 'ant-design-vue';
import { Modal, Pagination, FormModel, Switch, Popconfirm, Tree, Select ,Dropdown} from 'ant-design-vue';
import { Avatar,Divider,Empty,Checkbox,Radio,Tabs,Badge,Upload,Tag,Tooltip,InputNumber,DatePicker,Result,ConfigProvider} from 'ant-design-vue';


Vue.use(Button).use(Row).use(Col).use(Icon).use(Form).use(Input).use(Layout).use(Menu).use(Breadcrumb).use(Spin).use(Table);
Vue.use(Modal).use(Pagination).use(FormModel).use(Switch).use(Popconfirm).use(Tree).use(Select).use(Dropdown).use(DatePicker).use(ConfigProvider);
Vue.use(Avatar).use(Divider).use(Empty).use(Checkbox).use(Radio).use(Tabs).use(Badge).use(Upload).use(Tag).use(Tooltip).use(InputNumber).use(Result);
Vue.use(VueRouter);
import VueDND from 'awe-dnd'
Vue.use(VueDND)

import 'viewerjs/dist/viewer.css';
import viewer from 'v-viewer';
Vue.use(viewer, {
    defaultOptions: {
        title: false
    }
});

import DateUtil from '@/utils/dateUtil.js';
Vue.prototype.DateUtil = DateUtil;
//  if (localStorage.getItem('debug')) {
   //const vConsole = new Vconsole();
   //Vue.use(vConsole);
//  }
Vue.config.productionTip = false;
Vue.prototype.doUploader = doUploader;
// 消息提示框全局配置
Vue.prototype.$message = message;
Vue.prototype.$message.config({
    top: '70px',
    duration: 1,
    maxcount: 3
});
Vue.prototype.$notification = notification;
// confirm 全局配置
Vue.prototype.$confirm = function confirmFn(props) {
    const {
        // 默认图标
        icon = function icon(h) {
            return h(Icon, {
                attrs: {
                    type: 'exclamation-circle',
                    theme: 'filled',
                    style: {
                        color: '#faad14'
                    }
                }
            });
        },
        // 确认和取消按钮更换位置
        cancelButtonProps = {
        //     style: {
        //         float: 'right',
        //         marginLeft: '8px'
        //     }
        },
        // 在屏幕中心显示
        centered = true,
        ...confirmProps
    } = props;
    const config = _extends(
        {
            type: 'confirm',
            okCancel: true
        },
        {
            icon,
            cancelButtonProps,
            centered,
            ...confirmProps
        }
    );
    return modalConfirm(config);
};
Vue.prototype.$isNotLoginConfirm = function isNotLoginConfirm(){
    Vue.prototype.$confirm({
        title: '请登录',
        content:"您还没有登录是否前往登录页面？",
        okText: '登录',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
            showDrawer({                
                drawer: true,
                callback: val => {
                    console.log(val, 111)
                }
            });
        },
        onCancel: () => {
        }
    });
}
Vue.prototype.$isLogin= function isLogin(){
    if(store.getters.isLogin){
        return true;
    }
    let token = localStorage.getItem(ACCESS_TOKEN);
    if(token){
        store.dispatch('GetInfo').then(res => {
            if(store.getters.isLogin){
                return true;
            }else{
                return false;
            }
        });
    }else{
        return false;
    }
},

Vue.prototype.$setBack = function setBack(back){
    localStorage.setItem("isBack",back);

}
Vue.prototype.$isBack = function isBack(){
    let isBack = localStorage.getItem("isBack");
    if(isBack && (isBack===1 || isBack==="1")){
        return true;
    }else{
        return false;
    }
}
Vue.prototype.$goBack = function goBack(){
    Vue.prototype.$setBack(1);
    router.back();
},

Vue.prototype.$setScrollTop = function setScrollTop(scrollTop){
    setTimeout(()=>{document.body.scrollTop =  scrollTop;}, 500);
    Vue.prototype.$setBack(0);
}
Vue.prototype.$setUrlData = function setUrlData(url,data){
    localStorage.setItem(url,data);
}
Vue.prototype.$getUrlData = function getUrlData(url){
    return localStorage.getItem(url);
}
router.beforeEach((to, from, next) => {
    beforeEach(to, from, next);
});


router.afterEach(() => {});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
