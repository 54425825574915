const DateUtil = {};

DateUtil.MINUTE = 1000 * 60;
DateUtil.HOUR = DateUtil.MINUTE * 60;
DateUtil.DAY = DateUtil.HOUR * 24;
DateUtil.WEEK = DateUtil.DAY * 7;
//时间转换到本地的天
DateUtil.toLocaleDateString = function (datestr) {
    let date = new Date(datestr);
    let m = date.getMonth() + 1;
    if (m < 10) {
        m = '0' + m;
    }
    let day = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '-' + m + '-' + day;
};

DateUtil.toLocaleMinutString = function (datestr) {
    let date = new Date(datestr);

    let m = date.getMonth() + 1;
    if (m < 10) {
        m = '0' + m;
    }
    let day = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }

    let h = date.getHours();
    if (h < 10) {
        h = '0' + h;
    }
    let MM = date.getMinutes();
    if (MM < 10) {
        MM = '0' + MM;
    }
    return `${date.getFullYear()}-${m}-${day} ${h}:${MM}`;
};

//时间转换成显示时间 如比较进的显示多少分钟前 这种
DateUtil.toShowTime = function (datestr) {
    // datestr = datestr.replace(new RegExp(/-/gm) ,'/');
    let curdate = new Date();
    let date = new Date(datestr);
    let dt = curdate.getTime() - date.getTime();
    if (dt < DateUtil.HOUR) {
        let MINUTE = dt / DateUtil.MINUTE;
        MINUTE = Math.max(MINUTE, 1);
        MINUTE = Math.floor(MINUTE);
        return MINUTE + '分钟前';
    }
    if (dt < DateUtil.DAY) {
        let HOUR = dt / DateUtil.HOUR;
        HOUR = Math.max(HOUR, 1);
        HOUR = Math.floor(HOUR);
        return HOUR + '小时前';
    }
    if (dt < DateUtil.WEEK) {
        let DAY = dt / DateUtil.DAY;
        DAY = Math.max(DAY, 1);
        DAY = Math.floor(DAY);
        return DAY + '天前';
    }
    return DateUtil.toLocaleDateString(datestr);
};

DateUtil.toShowTimeFormat = function (datestr) {
    // datestr = datestr.replace(new RegExp(/-/gm), '/');
    let curdate = new Date();
    let date = new Date(datestr);
    let dt = curdate.getTime() - date.getTime();
    if (dt < DateUtil.HOUR) {
        let MINUTE = dt / DateUtil.MINUTE;
        MINUTE = Math.max(MINUTE, 1);
        MINUTE = Math.floor(MINUTE);
        return MINUTE + '分钟前';
    }
    if (dt < DateUtil.DAY) {
        let HOUR = dt / DateUtil.HOUR;
        HOUR = Math.max(HOUR, 1);
        HOUR = Math.floor(HOUR);
        return HOUR + '小时前';
    }
    if (dt < DateUtil.WEEK) {
        let DAY = dt / DateUtil.DAY;
        DAY = Math.max(DAY, 1);
        DAY = Math.floor(DAY);

        if (DAY > 2) {
            return DateUtil.toLocaleDateString(date);
        } else {
            return DAY + '天前';
        }
    }
    return DateUtil.toLocaleDateString(datestr);
};

export default DateUtil;
