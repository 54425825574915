import moment from 'moment';
import store from '@/store';
import { REFRESH_TOKEN,ACCESS_TOKEN } from '@/store/mutation-types'; // DEFAULT_LAYOUT_MODE

const whiteList = ['403', '404', '500', 'userList','login']; // no redirect whitelist

const base_url = `${process.env.VUE_APP_DINGDUAN_BASE_URL}/#`
//网站地址
const HomeUrl = process.env.VUE_APP_DINGDUAN_BASE_URL;
const TopicUrl = base_url + '/topics';
const TopicDetailUrl = base_url + '/topics/detail/{uuid}';
const CircleUrl = base_url + '/circles';
const CircleDetailUrl = base_url + '/circles/{uuid}';
const CircleFollowersUrl =base_url + '/circles/{uuid}/followers';
const SubjectUrl = base_url + '/subjects';
const SubjectDetailUrl = base_url + '/subjects/{uuid}';
const MineUrl = base_url + '/mine';
const MineMessageUrl = base_url + '/mine/message';
const SearchUrl = base_url + "/search"

//小程序地址
const AppletHomeUrl = base_url + '/applet';
const AppletSearchUrl= base_url + '/applet/search';
const AppletHotActUrl= base_url + '/applet/activity/hot';
const AppletHotSucUrl= base_url + '/applet/subject/hot';
const AppletSubjectDetailUrl= base_url + '/applet/subject/detail/{uuid}';
const AppletTopicDetailUrl= base_url + '/applet/topic/detail/{uuid}';
const AppletUserDetailUrl= base_url + '/applet/user/detail/{uuid}?isVirtualUser={userType}';
const AppletTopicAdd= base_url + '/applet/topic/add';
const AppletCircleDetailUrl = base_url + '/applet/circles/detail/{uuid}';

/**
 * 打开页面前操作
 * 设置用户信息
 */
export function beforeEach(to, from, next) {
    if(to.query && to.query.token){
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.setItem(ACCESS_TOKEN,to.query.token);
        // localStorage.setItem(ACCESS_TOKEN,"u_token:7d1c322ba74a9b5ef2debdaa6f744ab7");
    }
    if(to.query && to.query.openId){
        localStorage.removeItem("openid");
        localStorage.setItem("openid",to.query.openId);
    }
    console.log(localStorage.getItem(ACCESS_TOKEN));
    store.dispatch('getConfigurationList');
    if (to.meta.title) {
        document.title = to.meta.title
    }
  
    //通过用户名判断是否包含用户信息 后续可以根据实际要求更改
    if(localStorage.getItem(ACCESS_TOKEN) && localStorage.getItem(ACCESS_TOKEN)!='null'  ){
        if (!store.getters.userInfo.uuid) {
            //获取用户信息
            store.dispatch('GetInfo').then(res => {
                    next();
            });
            // 获取用户封禁信息
            store.dispatch('GetUserBanInfo').then(res => {
                    next();
            });
        } else {
            next();
        }
    }else{
        next();
    }
}

/**
 * Home
 */
export function getHomeUrl(id) {
    return HomeUrl;
}
/**
 * 生成帖子url
 */
export function getTopicUrl(id) {
    return TopicUrl;
}

/**
 * 生成帖子详情url
 */
export function getTopicDetailUrl(id) {
    return TopicDetailUrl.replace(/{uuid}/g,id);
}

/**
 * 获得圈子url
 */
export function getCircleUrl(id) {
    return CircleUrl;
}

/**
 * 获得圈子详情url
 */
export function getCircleDetailUrl(id) {
    return CircleDetailUrl.replace(/{uuid}/g,id);
}

/**
 * 获得圈子成员列表url
 */
export function getCircleFollowersUrl(id) {
    return CircleFollowersUrl.replace(/{uuid}/g,id);
}

/**
 * 获得话题url
 */
export function getSubjectUrl(id) {
    return SubjectUrl;
}

/**
 * 获得话题详情url
 */
export function getSubjectDetailUrl(id) {
    return SubjectDetailUrl.replace(/{uuid}/g,id);
}

/**
 * 获得我的url
 */
export function getMineUrl(id) {
    return MineUrl;
}

/**
 * 获得我的消息url
 */
export function getMineMessageUrl(id) {
    return MineMessageUrl;
}


/**
 * 获得搜索url
 */
export function getSearchUrl(id) {
    return SearchUrl;
}

/**
 * 获得小程序首页url
 */
export function getAppletUrl(id) {
    return AppletHomeUrl;
}

/**
 * 获得小程序搜索url
 */
export function getAppletSearchUrl(id) {
    return AppletSearchUrl;
}

/**
 * 获得小程序热门活动url
 */
export function getAppletHotActUrl(id) {
    return AppletHotActUrl;
}

/**
 * 获得小程序热门话题Url
 */
export function getAppletHotSucUrl(id) {
    return AppletHotSucUrl;
}

/**
 * 获得小程序话题详情Url
 */
export function getAppleSubjectDetailUrl(id) {
    return AppletSubjectDetailUrl.replace(/{uuid}/g,id);;
}

/**
 * 获得小程序帖子详情Url
 */
export function getAppleTopicDetailUrl(id) {
    return AppletTopicDetailUrl.replace(/{uuid}/g,id);;
}

/**
 * 获得小程序用户详情Url
 */
export function getAppletUserDetailUrl(id,userType) {
    return AppletUserDetailUrl.replace(/{uuid}/g,id).replace(/{userType}/g,userType);
}

/**
 * 获得小程序添加帖子页面
 */
export function getAppletTopicAdd(id) {
    return AppletTopicAdd;
}

/**
 * 获得小程序圈子详情url
 */
 export function getAppletCircleDetailUrl(id) {
    return AppletCircleDetailUrl.replace(/{uuid}/g,id);
}

