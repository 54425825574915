import { axios,customAxiosForm} from '@/utils/request';
import {ACCESS_TOKEN} from '@/store/mutation-types';

const USER_BASE_URL =  process.env.VUE_APP_USER_BASE_URL
const COMMON_BASE_URL =  process.env.VUE_APP_COMMON_BASE_URL
const TOKEN_BASE_URL =  process.env.VUE_APP_TOKEN_BASE_URL
const NEWS_BASE_URL =  process.env.VUE_APP_NEWS_BASE_URL
const VUE_APP_SWUSER_URL =  process.env.VUE_APP_SWUSER_URL

const api = {
    login: `${USER_BASE_URL}/login/user/passwordLogin`,
    // smsSend:`${USER_BASE_URL}/user/sms/send`,
    smsSend:`${COMMON_BASE_URL}/user/sms/send`,
    smsLogin:`${USER_BASE_URL}/login/user/captchaLogin`,
    logout:`${USER_BASE_URL}/login/user/quit`,
    userInfo:`${NEWS_BASE_URL}/api/user/info`,
    adminUserInfo:`${TOKEN_BASE_URL}/api/token/info`,
};


/**
 * 获取 token
 */
export function login(data) {
    return axios({
        url: api.login,
        method: 'post',
        data:data,
    });
}


/**
 * 获取验证码
 * @param {String} cellphone 手机号
 */
export function getSmsCaptcha(cellphone) {
    let formdata = new FormData()
    formdata.append('u_cellphone', cellphone)
    formdata.append('captchaType', 'login')
    return axios({
        url: api.smsSend,
        method: 'post',
        data: formdata
        // data: {
        //     u_cellphone:cellphone
        // }
    });
}

/**
 * 验证码登录
 * @param {String} cellphone 手机号
 */
export function smsLogin(params) {
    return axios({
        url: api.smsLogin,
        method: 'post',
        data:params,
    });
}

/**
 * 退出登录
 */
export function logout() {
    return axios({
        url:api.logout,
        method: 'post',
    });
}

/**
 * 获取登录用户管理员信息
 */
export function getAdminUserInfo(data) {
    return axios({
        url: api.adminUserInfo,
        method: 'post',
        data:{}
    });
}

/**
 * 获取登录用户信息
 */
export function getUserInfo(data) {
    return axios({
        url: api.userInfo,
        method: 'post',
        data:{}
    });
}

/**
 * 向后台同步用户信息
 */
export function saveUserInfo(data) {
    return axios({
        url: `/biz/v1/users/${data.uuid}/save`,
        method: 'post',
        data:data
    });
}

/**
 * 获取登录用户封禁信息
 */
export function getUserBanInfo(data) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return axios({
        url: `${VUE_APP_SWUSER_URL}/service/userban/getUserBanInfo?token=${accessToken}`,
        method: 'get',
        data:{}
    });
}
