import Vue from 'vue';
import { Message } from 'ant-design-vue';
import { login, logout,getAdminUserInfo,getUserInfo, getUserBanInfo } from '@/api/login';
import * as  RouterUtils from '@/utils/routerUtils';
import {ACCESS_TOKEN} from '@/store/mutation-types';
const user = {
    state: {
        token: '',
        name: '',
        username: '',
        avatar: '',
        isLogin:false,
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_USERNAME: (state, username) => {
            state.username = username;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ISLOGIN: (state, isLogin) => {
            state.isLogin = isLogin;
        },
        SET_INFO: (state, info) => {
            state.info = info;
        }
    },

    actions: {
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem(ACCESS_TOKEN);
                if(token){
                    const expiresAt = new Date().getTime() + 60*60*60*24*14*1000;
                    // sessionStorage.setItem(ACCESS_TOKEN, token);
                    localStorage.setItem(ACCESS_TOKEN, token);
                    userInfo&&commit('SET_TOKEN', token)
                }
                resolve();
            });
        },
        // 获取用户信息
        GetInfo({ commit }) {
            const result = {
                access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uIjoiMjM5OGM3MzgtZWMzYi00M2RiLThlOTEtMjZmOTZjMDUyMjFiIiwicm9sZXMiOlsiNWY3ZDgyZWRhNTllNjQxM2MyODg2MzI2Il0sInNjb3BlIjpbInV1YXMiLCJkYW1zIl0sIm5hbWUiOiLmnajkuprmhaciLCJuaWNrbmFtZSI6bnVsbCwiYXZhdGFyIjpudWxsLCJleHAiOjE2NDg0NTI0NTYsInV1aWQiOiI2MjFmMTYwMDYwYjJiNTk3MGI4NDU3ODYiLCJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImp0aSI6IjRmOGQ2ZjBiLWE1NDEtNGFmYi1iMDVlLWI0NTgyZjNmZTQ3YyIsImNsaWVudF9pZCI6Im5ld3Nyb29tIiwidXNlcm5hbWUiOiJ5YW5neWFodWkifQ.Vw2Zk23N4gruubBJZ_bd0fWMCFtkhTIZkvJxyyLvImM',
                refresh_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uIjoiMjM5OGM3MzgtZWMzYi00M2RiLThlOTEtMjZmOTZjMDUyMjFiIiwicm9sZXMiOlsiNWY3ZDgyZWRhNTllNjQxM2MyODg2MzI2Il0sImF2YXRhciI6bnVsbCwidXVpZCI6IjYyMWYxNjAwNjBiMmI1OTcwYjg0NTc4NiIsImF1dGhvcml0aWVzIjpbIlJPTEVfQURNSU4iXSwiY2xpZW50X2lkIjoibmV3c3Jvb20iLCJzY29wZSI6WyJ1dWFzIiwiZGFtcyJdLCJhdGkiOiI0ZjhkNmYwYi1hNTQxLTRhZmItYjA1ZS1iNDU4MmYzZmU0N2MiLCJuYW1lIjoi5p2o5Lqa5oWnIiwibmlja25hbWUiOm51bGwsImV4cCI6MTY0ODQ3NzY1NiwianRpIjoiZTEyNGZhNWEtNjQyYS00ZjMwLWI4YzctZDQxMmI4MGZhM2YxIiwidXNlcm5hbWUiOiJ5YW5neWFodWkifQ.VIz0bwOR9OaSzVAmHNTJJLWI7bABCWVaK8eJ2vvT7yQ',
                username: '管理员',
                nickname: '张三',
                expires_in: '1000000000'
            };
            return new Promise(resolve => {
                getUserInfo().then(res => {
                    const userInfo =  res.data;
                    if(userInfo){
                        //用户认证信息列表（merchant 商户认证 identity_verification 实名认证 reporter记者认证 copyreader编辑认证 ）
                        let isCertification = "0";
                        let certificationType = "0";
                        userInfo.identities_info &&  userInfo.identities_info.forEach((item, index) => {
                            if(item === "identity_verification"){
                                isCertification = "1";
                            }else if(item === "merchant"){
                                certificationType = "2";
                            }else if(item === "copyreader" || item === "reporter"){
                                certificationType = "3";
                            }
                        });
                        if(certificationType == '0' && isCertification=="1"){
                            certificationType = '1'
                        }
                        
                        const accessToken = localStorage.getItem(ACCESS_TOKEN);
                        userInfo.certificationType = certificationType; //认证类型0：未认证 1：个人认证 2：企业认证 3：机构认证
                        userInfo.isCertification = isCertification; //是否实名认证 0：未认证 1：已认证
                        userInfo.uuid = userInfo.u_id;
                        userInfo.nickname = userInfo.u_nickname ? userInfo.u_nickname : "默认用户名";
                        userInfo.avatar = userInfo.u_avatar;
                        commit('SET_TOKEN', accessToken);
                        commit('SET_NAME',  userInfo.nickname? userInfo.nickname:'默认用户名');
                        commit('SET_USERNAME',  userInfo.nickname? userInfo.nickname:'默认用户名');
                        commit('SET_AVATAR', userInfo.avatar);
                        commit('SET_ISLOGIN', true);
                        commit('SET_INFO', userInfo);
                        resolve({
                            result: userInfo
                        });
                    }else{
                        //正式使用
                        sessionStorage.removeItem(ACCESS_TOKEN);
                        localStorage.removeItem(ACCESS_TOKEN);
                        // window.open(RouterUtils.getAppletUrl(),"_self")
                        window.open(RouterUtils.getHomeUrl(),"_self")
                    }
                });
            });
        },

        // 获取用户信息
        GetUserBanInfo ({ commit }) {
            return new Promise(resolve => {
                getUserBanInfo().then(res => {
                    if (res.code == 0) {
                        if (res.data.ban_type == 2) {
                            Message.warn(res.data.ban_reason);
                            localStorage.removeItem(ACCESS_TOKEN);
                            setTimeout(() => {
                                window.location.reload();
                            }, 100);
                        } else {
                            localStorage.setItem("banData", JSON.stringify(res.data));
                        }
                    } else {
                        console.log(res.msg);
                    }
                });
            });
        },

        // 登出
        Logout({ commit, state }) {
            return new Promise(resolve => {
                logout().finally(() => {
                    commit('SET_TOKEN', '');
                    commit('SET_NAME', '');
                    commit('SET_USERNAME', '');
                    commit('SET_AVATAR', '');
                    commit('SET_ISLOGIN', false);
                    commit('SET_INFO', {});
                    localStorage.removeItem(ACCESS_TOKEN);
                    resolve();
                });
            });
        }
    }
};

export default user;
